input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.imageUpload {
  i {
    font-size: 2rem;
    color: #1c1c1ca9;
  }
}
.top-icons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;
  background: #ffffff;
  .edit-icon {
    padding: 1px 3px;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: green;
      transform: scale(1.2);
    }
  }
  .delete-icon {
    padding: 1px 3px;
    transition: all ease-in-out 0.3s;
    &:hover {
      color: red;
      transform: scale(1.2);
    }
  }
}

// .phone_input_new .PhoneInputInput,
// .phone_input_new .PhoneInputCountryIcon {
//   display: none !important;
// }
// .phone_input_new .PhoneInputCountrySelect {
//   width: 100px !important;
// }
// .PhoneInput {
//   height: 36px !important;
// }
// .PhoneInputCountry,
// .PhoneInputCountrySelect {
//   height: 100% !important;
// }
.PhoneInputInput {
  width: 100% !important;
  border-radius: 5px;
  padding: 0 10px;
  border: 1px solid #ced4da;
}
.PhoneInputCountry {
  width: 140px;
  display: flex;
  flex-direction: row-reverse;
}
.PhoneInputCountrySelect {
  width: 100%;
  margin: 0 10px;
  border-color: #ced4da;
  border-radius: 5px;
}
.PhoneInput {
  display: flex !important;
  height: 36px !important;
  width: 100% !important;
}
.PhoneInputCountryIconImg {
  width: 30px !important;
  height: 36px !important;
}

.image_wrapper {
  img {
    object-fit: cover;
    border-radius: 5px;
  }
  position: relative;
  max-height: 120px;
  .edit_image_btn {
    display: none;
  }
  .defualt_images {
    filter: grayscale(100%);
  }
  &:hover {
    .shadow {
      position: absolute;
      background-color: #00000061;
      border-radius: 5px;
      height: 100%;
      width: 100%;
      top: 0;
      transition: all 0.3s ease-in-out;
    }
    .edit_image_btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 18px;
      display: block;
    }
  }
}

.css-2b097c-container {
  width: 100% !important;
}
.navbar-brand-box {
  text-align: start;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
.myScrollCard {
  max-height: 740px;
  overflow-y: scroll;
}
.myScrollCard::-webkit-scrollbar {
  display: none;
}
.apexcharts-legend {
  // display: none !important;
}
.h-240 {
  height: 200px;
}
.text-green {
  color: #34c38f;
}

// custom end

.editor-class {
  height: auto !important;
  min-height: 250px;
}
.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}
.overflow-div {
  overflow-y: scroll;
  // max-height: 100vh;
}
.overflow-div::-webkit-scrollbar {
  display: none;
}
.fc .fc-toolbar-title {
  font-size: 1.3em;
}

.fc-daygrid-event {
  border: none;
}
.selection-cell {
  display: none;
}
.selection-cell-header {
  display: none;
}
#select-cell .selection-cell {
  display: block !important;
}
#select-cell .selection-cell-header {
  display: block !important;
}

// .overflowScroll {
//   // overflow-x: scroll;

// }
.max-h-5::-webkit-scrollbar,
.overflowScroll::-webkit-scrollbar {
  display: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #444444 !important;
}

.btn.custom_border_rad {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
.custome_select_rad .css-yk16xz-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.createClintBtn {
  padding-right: 0px;
}
.createClintBtnCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.borderBottomWhite {
  border-bottom: 1px solid #fff;
}
.myWidth30 {
  width: 30% !important;
}
.myWidth20 {
  width: 20% !important;
}
.width-fit {
  width: fit-content !important;
  margin-left: auto;
}
@media print {
  .bg-info-new {
    color: #000 !important;
    background-color: #f8f9fa !important;
    -webkit-print-color-adjust: exact;
  }
  .bg-light-new {
    background-color: #f8f9fa !important;
    -webkit-print-color-adjust: exact;
  }
  .print-pb-3 {
    padding-bottom: 2rem;
  }
  .print-pt-3 {
    padding-top: 2rem;
  }
  .print-boxshadow {
    box-shadow: none !important;
  }
  .display-none {
    display: none !important;
  }
  .display-block {
    display: block !important;
  }
  // #header,
  // #footer,
  #nav {
    display: none !important;
  }
}

@media screen and (max-width: 986px) {
  .createClintBtn,
  .createClintBtnCont {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media screen and (max-width: 600px) {
  .d-none-smo {
    display: none;
  }
}

.btn-wrap {
  flex-direction: column;
}

@media screen and (max-width: 540px) {
  .sm\:p-0 {
    padding: 0 !important;
  }
  .btn-wrap {
    flex-direction: row;
  }
}
